import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ContentBuilder from '../components/ContentBuilder'
import SEO from '../components/Seo'

const ProjectPage = ({ data }) => {
  return (
    <Layout title={'4fb'}>
      <SEO title={data.sanityProjects.slug.current} />
      {data.sanityProjects.pageContent.map(content => ContentBuilder(content))}
    </Layout>
  )
}

export default ProjectPage

export const query = graphql`
  query projectById($id: String) {
    sanityProjects(_id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageContent {
        ... on SanityContentText {
          id
          _type
          isNoMarginBottom
          isNoMarginTop
          isInvert
          centered
          _rawText(resolveReferences: { maxDepth: 6 })
        }
        ... on SanityContentImage {
          id
          _type
          image {
            asset {
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          textData {
            title
          }
        }
        ... on SanityImageAndText {
          id
          _type
          isLeftToRight
          isNoMarginBottom
          isNoMarginTop
          isRightToLeft
          _rawText(resolveReferences: { maxDepth: 6 })
          image {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanitySlideshow {
          id
          _type
          title
          slideshowimages {
            image {
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityTile {
          id
          _type
          pageContent {
            id
            title
            _rawText(resolveReferences: { maxDepth: 6 })
          }
        }
      }
    }
  }
`
